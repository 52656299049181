import {
  getGroupBySubjectsAVG,
  getDealerGroupBySubjectsAVG,
  getMeasurementsById,
  getMeasurementsAvg,
  getMeasurementsAbnormalRate,
} from '@/api/measurement'
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from '@/utils/localStorage'

const storeMeasurementFromLocal = getLocalStorage('storeMeasurement') || {}
const {
  measurementDataAll,
  measurementDataAllAmount,
  measurementDataDetailAmount,
  measurementDataDetail,
  conditionForMeasurementDataAll,
  measurementsAvg,
  measurementsAbnormalRate,
} = storeMeasurementFromLocal

const state = () => ({
  measurementDataAllAmount: measurementDataAllAmount || 0,
  measurementDataAll: measurementDataAll || [],
  measurementDataDetailAmount: measurementDataDetailAmount || 0,
  measurementDataDetail: measurementDataDetail || [],
  conditionForMeasurementDataAll: conditionForMeasurementDataAll || {},
  measurementsAvg: measurementsAvg || {},
  measurementsAbnormalRate: measurementsAbnormalRate || {},
})

const getters = {
  measurementDataAllAmount: (state) => state.measurementDataAllAmount,
  measurementDataAll: (state) => state.measurementDataAll,
  measurementDataDetailAmount: (state) => state.measurementDataDetailAmount,
  measurementDataDetail: (state) => state.measurementDataDetail,
  conditionForMeasurementDataAll: (state) =>
    state.conditionForMeasurementDataAll,
  measurementsAvg: (state) => state.measurementsAvg,
  measurementsAbnormalRate: (state) => state.measurementsAbnormalRate,
}

const mutations = {
  setMeasurementDataAllAmount(state, measurementDataAllAmount) {
    state.measurementDataAllAmount = measurementDataAllAmount
    setLocalStorage('storeMeasurement', state)
  },
  setMeasurementDataAll(state, measurementDataAll) {
    state.measurementDataAll = measurementDataAll
    if (!measurementDataAll || measurementDataAll.length <= 0) {
      state.measurementDataAllAmount = 0
    }
    setLocalStorage('storeMeasurement', state)
  },
  setMeasurementDataDetail(state, measurementDataDetail) {
    state.measurementDataDetailAmount = measurementDataDetail.amount || 0
    state.measurementDataDetail = measurementDataDetail.records || []
    setLocalStorage('storeMeasurement', state)
  },
  setConditionForMeasurementDataAll(state, conditionForMeasurementDataAll) {
    state.conditionForMeasurementDataAll = conditionForMeasurementDataAll
    setLocalStorage('storeMeasurement', state)
  },
  setMeasurementsAvg(state, measurementsAvg) {
    state.measurementsAvg = measurementsAvg
    setLocalStorage('measurementsAvg', state)
  },
  setMeasurementsAbnormalRate(state, measurementsAbnormalRate) {
    state.measurementsAbnormalRate = measurementsAbnormalRate
    setLocalStorage('measurementsAvg', state)
  },
}

const actions = {
  async getMeasurementDataAll({ commit }, postBody) {
    try {
      commit('setConditionForMeasurementDataAll', {})
      const data = await getGroupBySubjectsAVG(postBody)
      if (data.ok === true && data.data) {
        commit('setMeasurementDataAll', data.data)
        commit('setMeasurementDataAllAmount', data.total)
        commit('setConditionForMeasurementDataAll', postBody)
      } else {
        commit('setMeasurementDataAll', [])
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async getMeasurementDataAllByDealerStation({ commit }, postBody) {
    try {
      commit('setConditionForMeasurementDataAll', {})
      const data = await getDealerGroupBySubjectsAVG(postBody)
      if (data.ok === true && data.data) {
        commit('setMeasurementDataAll', data?.data?.result)
        commit('setMeasurementDataAllAmount', data?.data?.total)
        commit('setConditionForMeasurementDataAll', postBody)
      } else {
        commit('setMeasurementDataAll', [])
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },

  async clearMeasurementDataAll({ commit }) {
    try {
      commit('setConditionForMeasurementDataAll', {})
      commit('setMeasurementDataAll', [])
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  clearMeasurementDetailData({ commit }) {
    try {
      commit('setMeasurementDataDetail', {})
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async getMeasurementsById({ commit }, postBody) {
    try {
      const data = await getMeasurementsById(postBody)
      if (data.ok === true && data.data) {
        commit('setMeasurementDataDetail', data.data)
      } else {
        commit('setMeasurementDataDetail', {})
      }
    } catch (e) {
      commit('setMeasurementDataDetail', {})
      console.log('exception')
      console.log(e)
    }
  },
  async getMeasurementsAvg({ commit }, queryBody) {
    try {
      const data = await getMeasurementsAvg(queryBody)
      if (data.ok === true && data.data) {
        commit('setMeasurementsAvg', data.data)
      } else {
        commit('setMeasurementsAvg', {})
      }
    } catch (e) {
      commit('setMeasurementsAvg', {})
      console.log('exception')
      console.log(e)
    }
  },
  async getMeasurementsAbnormalRate({ commit }, queryBody) {
    try {
      const data = await getMeasurementsAbnormalRate(queryBody)
      if (data.ok === true && data.data) {
        commit('setMeasurementsAbnormalRate', data.data)
      } else {
        commit('setMeasurementsAbnormalRate', {})
      }
    } catch (e) {
      commit('setMeasurementsAbnormalRate', {})
      console.log('exception')
      console.log(e)
    }
  },
  async clearAllMeasurementData({ commit }) {
    try {
      commit('setMeasurementDataDetail', {})
      commit('setMeasurementDataAll', [])
      removeLocalStorage('storeMeasurement')
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
}

export default { state, getters, mutations, actions }
