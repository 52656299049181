import { isJson } from '@/utils/validate'

export const getLocalStorage = (key) => {
  const value = localStorage.getItem(key)
  if (isJson(value)) {
    return JSON.parse(value)
  } else {
    return value
  }
}

export const setLocalStorage = (key, jsonObj) => {
  if (typeof jsonObj != 'string') {
    localStorage.setItem(key, JSON.stringify(jsonObj))
  } else {
    localStorage.setItem(key, jsonObj)
  }
}

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key)
}
